@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --orange: #f35b2466;
  --orange: #8eaf55;
}

body {
  margin: 0;
  /* font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

button {
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#react-select-5-listbox {
  z-index: 999999999999999 !important;
}
.input-md{
  @apply flex-1 appearance-none rounded-lg border border-gray-300 w-full py-4 2xl:py-8 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#88a848]  focus:border-transparent
}
.NyscBtn1{
  @apply h-6 w-20 bg-[#ECFFCC] text-[#87AA4B] font-bold text-[12px] rounded-lg
}
.NyscBtn2{
  @apply h-6 w-20 bg-[#FFDACC] text-[#F35B24] font-bold text-[12px] rounded-lg
}
.tox-statusbar__branding{
  display: none !important;
}
.question-table table, .question-table col, .answer-table table,.answer-table col {
   border: 1px solid rgb(228, 225, 225) ;   
}
.question-table td, .answer-table td{
  padding-left: 3px;
  padding-top: 6px;
   padding-bottom: 6px;
}
.question-table tr, .answer-table tr{
   border-bottom: 1px solid rgb(228, 225, 225);    
}
.answer-table table{
   width: 300px !important;
}
.focus-border-0:focus, .focus-border-0:active, .focus-border-0:hover{
    border: none ;
    outline: none;
}
.lalHag{
  @apply border border-gray-200 rounded-lg mb-4
}
div[data-tag="allowRowEvents"]{
  white-space: pre-wrap !important;
  text-overflow: unset !important;
}
.admin-sidebar::-webkit-scrollbar{
  display: none;
}
.admin-sidebar{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 
@media screen and (max-width: 500px) {
  .m-hidden{
    display: none;
  }
}
.jodit-status-bar{
  display: none !important;
}
.sign-up-container{
  background-image: url('./images/bg2.jpg');
}
.react-daterange-picker__wrapper{
  border: 1px solid #cacaca !important;
  padding: 5px 10px;
  border-radius: 5px;
}
.primary-form-input{
  @apply py-3 px-3 text-sm border border-gray-200 
  focus:outline-none focus:border-primary rounded-lg;
}
.btn{
  @apply py-3 px-8 text-white rounded-lg text-[12px]
}
.btn-light{
  @apply py-3 px-8 text-slate-900 rounded-lg text-[12px]
}
input[type="checkbox"] {
  width: 16px;
  height: 16px;
  border: 2px solid #cccccc; /* Light gray border */
  border-radius: 3px; /* Slightly rounded corners */
  background-color: #ffffff; /* White background */
  appearance: none; /* Revert to the default styling */
  outline: none; /* Remove the default outline */
}

input[type="checkbox"]:checked {
  background-color: #87aa4b !important; /* Green background */
  border-color: #87aa4b !important; /* Green border when checked */
  outline: none;
}

input[type="checkbox"]:checked::after {
  content: '';
  display: block;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: relative;
  left: 4px;
  top: 1px;
}

input[type="checkbox"]:focus {
  outline: none; /* Remove focus outline */
  box-shadow: 0 0 0 2px #87aa4b; /* Optional: Custom focus styling */
}

input[type="checkbox"]:hover {
  border-color: #999999; /* Darker border on hover */
}

input[type="checkbox"]:disabled {
  background-color: #f0f0f0; /* Light gray background when disabled */
  border-color: #cccccc; /* Light gray border when disabled */
  cursor: not-allowed; /* Show not-allowed cursor */
}
.section-input{
  @apply py-[11px] px-2 border focus:bg-white outline-none 
  duration-200 focus:border-customGreen focus:ring-0
   border-gray-200 rounded-lg text-sm block
}